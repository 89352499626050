import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

export const FavoriteBorderIcon: React.FC<React.PropsWithChildren<SvgProps>> = (props) => {
  return (
    <Svg viewBox="0 0 16 16" {...props}>
      <g clipPath="url(#clip0_316_14296)">
        <path d="M13.1067 2.66044C11.3467 1.46044 9.17334 2.02044 8.00001 3.39377C6.82668 2.02044 4.65334 1.45377 2.89334 2.66044C1.96001 3.30044 1.37334 4.38044 1.33334 5.52044C1.24001 8.1071 3.53334 10.1804 7.03334 13.3604L7.10001 13.4204C7.60668 13.8804 8.38668 13.8804 8.89334 13.4138L8.96668 13.3471C12.4667 10.1738 14.7533 8.10044 14.6667 5.51377C14.6267 4.38044 14.04 3.30044 13.1067 2.66044ZM8.06668 12.3671L8.00001 12.4338L7.93334 12.3671C4.76001 9.49377 2.66668 7.59377 2.66668 5.6671C2.66668 4.33377 3.66668 3.33377 5.00001 3.33377C6.02668 3.33377 7.02668 3.99377 7.38001 4.9071H8.62668C8.97334 3.99377 9.97334 3.33377 11 3.33377C12.3333 3.33377 13.3333 4.33377 13.3333 5.6671C13.3333 7.59377 11.24 9.49377 8.06668 12.3671Z" />
      </g>
      <defs>
        <clipPath id="clip0_316_14296">
          <rect width="16" height="16" />
        </clipPath>
      </defs>
    </Svg>
  );
};
